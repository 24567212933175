import { useMemo } from 'react';
import { useRouter } from 'next/router';

import { NavItem, PlatformID } from '@common/clients/api';
import { useContextData } from '@common/useContextData';
import { cleanLeadingSlash } from '@common/utils/UrlUtil';

interface State {
    mainItems: NavItem[];
    nonMainItems: NavItem[];
    currentNavItem?: NavItem;
    subItems: NavItem[];
    allItems: NavItem[];
}

const getCurrentNavItem = (navItems: NavItem[], currentUrl: string): NavItem | undefined => {
    return navItems.find((item) => item.url === currentUrl);
};

export const useNavItems = (menuOpen: boolean): State => {
    const contextData = useContextData();
    const { platform, navItems = [] } = contextData;
    const { asPath } = useRouter();

    const currentUrl = cleanLeadingSlash(asPath || '');

    const mainItems = useMemo(() => navItems.filter((item) => item.isMain), [navItems]);
    const nonMainItems = useMemo(() => navItems.filter((item) => !item.isMain), [navItems]);

    const subItems = useMemo(
        () => navItems.find((item) => item.url === currentUrl)?.children || mainItems[0]?.children || [],
        [navItems, mainItems, currentUrl],
    );

    const allItems = useMemo(
        () => [...mainItems, ...subItems, ...nonMainItems],
        [mainItems, subItems, nonMainItems],
    );

    const filteredMainItems = platform.id === PlatformID.VI ? [] : mainItems;
    const filteredSubItems = platform.id === PlatformID.VI ? mainItems : subItems;

    const currentNavItem = useMemo(() => getCurrentNavItem(allItems, asPath), [allItems, asPath]);
    return {
        mainItems: filteredMainItems,
        nonMainItems,
        currentNavItem,
        subItems: filteredSubItems,
        allItems: menuOpen ? allItems : [],
    };
};
