import { FC, useCallback, useState } from 'react';

import { Icon } from '@common/atoms/Icon';
import { Link } from '@common/atoms/Link';
import { NavItem, PlatformID } from '@common/clients/api';
import { InternalLink } from '@web/atoms/InternalLink/InternalLink';
import { ProTag } from '@web/atoms/ProTag';
import { ComponentList as NavItemList } from '@web/molecules';
import { ExpandableSearchBar } from '@web/molecules/ExpandableSearchBar';
import { PlatformToggle } from '@web/molecules/PlatformToggle';
import { Route } from '@web/routing/types';

import styles from './SubNavigation.module.scss';

export interface Props {
    items?: NavItem[];
    currentSearch?: string;
    currentNavItem?: NavItem;
    onSubmitSearch: (searchText: string) => void;
    menuView?: 'mobile' | 'desktop' | '';
}

export const SubNavigation: FC<Props> = ({
    items,
    currentSearch = '',
    onSubmitSearch,
    menuView,
    currentNavItem,
}) => {
    const [state, setState] = useState<{ expandedSearch: boolean }>({
        expandedSearch: false,
    });

    const expandSearch = useCallback((isExpanded: boolean) => {
        setState((prevState) => ({ ...prevState, expandedSearch: isExpanded }));
    }, []);

    const { expandedSearch } = state;

    const isHomeIconActive = currentNavItem?.url === '/' || currentNavItem?.url === '';
    return (
        <aside className={styles.SubNavigation}>
            <nav
                id="sub-nav"
                className={expandedSearch ? styles.collapsed : ''}
                role="navigation"
                aria-label="sub-navigation"
            >
                <InternalLink
                    className={
                        isHomeIconActive
                            ? `${styles['home-icon']} ${styles['is-active']}`
                            : styles['home-icon']
                    }
                    key="Homepage"
                    route={Route.Homepage}
                >
                    <Icon.home />
                </InternalLink>
                {items?.length ? (
                    <NavItemList
                        ComponentItem={(item: NavItem) => (
                            <li className={item.id === currentNavItem?.id ? styles['is-active'] : ''}>
                                {item.name === 'pro' ? (
                                    <InternalLink route={Route.Premium}>
                                        <ProTag />
                                    </InternalLink>
                                ) : (
                                    <Link className={styles[item.class ?? item.name]} href={item.url}>
                                        {item.name}
                                    </Link>
                                )}
                            </li>
                        )}
                        propsList={items}
                    />
                ) : null}
            </nav>
            {menuView !== 'mobile' ? (
                <PlatformToggle include={[PlatformID.GP, PlatformID.BR, PlatformID.VI]}>
                    <ExpandableSearchBar
                        variant={'default'}
                        additionalClass={`${styles['search-area']} ${expandedSearch ? styles.expanded : ''}`}
                        currentSearch={currentSearch}
                        onSubmit={onSubmitSearch}
                        isInverted={true}
                        onExpand={expandSearch}
                    />
                </PlatformToggle>
            ) : null}
        </aside>
    );
};
